<template>
  <v-sheet
    :color="$vuetify.theme.themes.dark.background"
    @mousemove="$emit('request-show-controls')"
  >
    <div
      class="d-flex justify-space-between px-4 controls-container"
      :class="{
        'show': showControls,
      }"
    >
      <eva-button
        icon="mdi-arrow-left"
        elevation="1"
        dense
        @click="$emit('click:previous')"
        @mouseover="$emit('mouseover:controls')"
        @mouseleave="$emit('mouseleave:controls')"
      />
      <eva-button
        icon="mdi-arrow-right"
        elevation="1"
        dense
        @click="$emit('click:next')"
        @mouseover="$emit('mouseover:controls')"
        @mouseleave="$emit('mouseleave:controls')"
      />
    </div>

    <v-img
      :src="selectedScreenshot.url"
      max-height="90vh"
      contain
    />
  </v-sheet>
</template>

<script>
import { onKeyStroke } from "@vueuse/core"

export default {
  name: "StudentScreenshotViewer",
  props: {
    studentScreenshots: {
      type: Array,
      required: true,
    },
    selectedScreenshot: {
      type: Object,
      required: true,
    },
    showControls: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "update:selectedScreenshot",
    "mouseover:controls",
    "mouseleave:controls",
    "request-show-controls",
    "click:next",
    "click:previous",
  ],
  setup(props, { emit }) {
    // Keyboard navigation
    onKeyStroke("ArrowRight", () => {
      emit("click:next")
    })
    onKeyStroke("ArrowLeft", () => {
      emit("click:previous")
    })
  },
}
</script>

<style scoped>
.controls-container {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  z-index: 10;
}

.controls-container.show {
  opacity: 1;
  transition: opacity 0.1s ease-out;
}
</style>
