<template>
  <dialog-self-wrapper
    v-model="isDialogOpen"
    title="Timeline"
    @exit-fullscreen="selectedScreenshot = null"
  >
    <div
      class="pa-4 floating-header"
      v-if="isDialogOpen"
    >
      <eva-button
        dense
        icon="mdi-close"
        @click="isDialogOpen = false"
        @mouseover="setControlsHovered(true)"
        @mouseleave="setControlsHovered(false)"
        class="hideable"
        :class="{
          'hideable-show': showControls,
        }"
        v-if="isDialogOpen"
      />
    </div>
    <student-screenshot-viewer
      v-if="selectedScreenshot && isDialogOpen"
      :selected-screenshot="selectedScreenshot"
      @update:selected-screenshot="selectedScreenshot = $event"
      :student-screenshots="studentScreenshots"
      :show-controls="showControls"
      @mouseover:controls="setControlsHovered(true)"
      @mouseleave:controls="setControlsHovered(false)"
      @request-show-controls="requestShowControls"
      @click:next="selectNextScreenshot"
      @click:previous="selectPreviousScreenshot"
    />
    <v-card
      :flat="!isDialogOpen"
      :outlined="isDialogOpen"
      :tile="!isDialogOpen"
      class="d-flex mt-auto"
      :class="{
        'floating-timeline': isDialogOpen,
        'ma-4': isDialogOpen,
        'pa-2': isDialogOpen,
        'hideable': isDialogOpen,
        'hideable-show': showControls,
      }"
      @mouseover="setControlsHovered(true)"
      @mouseleave="setControlsHovered(false)"
    >
      <div>
        <div
          v-for="(event, index) in [...shortEvents, ...longEvents]"
          :key="'event-icon-' + index"
          class="event-icon pr-2"
          v-tooltip="event.name"
        >
          <v-icon small>
            {{ event.icon }}
          </v-icon>
        </div>
      </div>

      <div class="timeline-container">
        <v-card
          flat
          text
          outlined
          class="timeline"
          @dblclick="handleTimelineDoubleClick"
        >
          <v-btn
            small
            @click="moveToLeft"
            @dblclick.stop="() => {}"
            class="move-button pa-0"
            style="left: 8px;"
            v-if="showLeftButton"
          >
            <v-icon small>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <v-btn
            small
            @click="moveToRight"
            @dblclick.stop="() => {}"
            class="move-button right pa-0"
            style="right: 8px;"
            v-if="showRightButton"
          >
            <v-icon small>
              mdi-arrow-right
            </v-icon>
          </v-btn>
          <div
            v-for="(event, index) in filteredLineEvents"
            :key="'line-' + index"
          >
            <div
              class="line smooth-move"
              :style="{
                left: ((event.date - minTimestamp) / duration * 100) + '%',
              }"
            />
            <v-menu
              open-on-hover
              offset-y
              top
              max-width="250"
              close-delay="100"
            >
              <template #activator="{on ,attrs}">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="hover-overlay"
                  :style="{
                    left: ((event.date - minTimestamp) / duration * 100) + '%',
                    width: index === filteredLineEvents.length - 1 ? 'calc(100% - ' + ((event.date - minTimestamp) / duration * 100) + '%)' : ((filteredLineEvents[index + 1].date - event.date) / duration * 100) + '%',
                  }"
                  :class="{
                    'primaryLight2': hoveredExerciseId === event.quizzes_exercise_id && hoveredExerciseId !== null,
                  }"
                  @mouseover="hoveredExerciseId = event.quizzes_exercise_id"
                  @mouseleave="hoveredExerciseId = null"
                />
              </template>

              <v-card
                @click="$emit('click:exercise', event.quizzes_exercise_id)"
              >
                <v-card-text>
                  <katex-md
                    :expr="event?.exercise?.displayName"
                    remove-all-html
                    style="max-height: 100px; overflow: auto;"
                  />
                  <template v-if="false">
                    <!--used for debug only-->
                    {{ timeHelpers.formatTimeDateWithSeconds(event.happened_at) }}
                  </template>
                </v-card-text>
                <v-card-text class="grey lighten-3">
                  <div>
                    {{ $tc(
                      "live.studentEvents.exerciseSeenCount",
                      exerciseEventsSummary(event.quizzes_exercise_id)?.seen_count,
                      {
                        count: exerciseEventsSummary(event.quizzes_exercise_id)?.seen_count,
                        duration: timeHelpers.formatDuration(exerciseEventsSummary(event.quizzes_exercise_id)?.duration)
                      })
                    }}
                  </div>
                  <keyboard-tooltip
                    :label="$t('live.studentEvents.seeExercise')"
                    key-name="E"
                  >
                    <a @click="$emit('click:exercise', event.quizzes_exercise_id)">
                      {{ $t("live.studentEvents.seeExercise") }}
                    </a>
                  </keyboard-tooltip>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
          <div
            v-for="(event, index) in filteredShortEvents"
            :key="'short-' + index"
            class="shortEventGroup mt-1"
          >
            <v-menu
              open-on-hover
              v-for="(timeEvent, subIndex) in event.events"
              :key="event.kind + index + subIndex"
              right
              :top="timeEvent.kind === 'answers_last_update'"
              offset-overflow
              offset-x
              offset-y
              max-width="250"
              :close-on-content-click="false"
            >
              <template #activator="{on ,attrs}">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="smooth-move short-event-marker"
                  :class="{
                    'big-short-event-marker': similarTimeEventsByGroup(timeEvent).length > 0 && timeEvent.kind !== 'answers_last_update',
                    'dot': timeEvent.kind !== 'answers_last_update',
                    'short-event-marker-line': timeEvent.kind === 'answers_last_update',
                  }"
                  :style="{
                    left: ((timeEvent.date - minTimestamp) / duration * 100) + '%',
                  }"
                >
                  <div
                    class="short-event-marker-background"
                    :class="event.color"
                  >
                    <span v-if="showEventTabId && timeEvent.kind !== 'answers_last_update'">
                      {{ tabIdFor(timeEvent?.details?.browser_tab_token) }}
                    </span>
                  </div>
                </div>
              </template>

              <quizzes-events-timeline-event-detail
                :event="timeEvent"
                :similar-events-by-group="similarTimeEventsByGroup(timeEvent)"
                :tab-id="tabIdFor(timeEvent?.details?.browser_tab_token)"
              />
            </v-menu>
          </div>
          <div
            v-for="(event, index) in filteredLongEvents"
            :key="'long-' + index"
            class="eventGroup mt-1"
          >
            <v-menu
              open-on-hover
              v-for="(timeEvent, subIndex) in event.events"
              :key="event.kind + index + subIndex"
              bottom
              offset-y
              max-width="250"
              :close-on-content-click="false"
            >
              <template #activator="{on ,attrs}">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="rectangle smooth-move"
                  :class="event.color"
                  :style="{
                    left: ((timeEvent.start - minTimestamp) / duration * 100) + '%',
                    width: ((timeEvent.end - timeEvent.start) / duration * 100) + '%',
                  }"
                >
                  <div
                    class="rectangle-background lighten-5"
                    :class="event.color"
                  />
                </div>
              </template>

              <quizzes-events-timeline-event-detail
                :event="timeEvent"
                :similar-events-by-group="similarTimeEventsByGroup(timeEvent)"
              />
            </v-menu>
          </div>

          <screenshots-timeline
            :student-screenshots="studentScreenshots"
            :min-timestamp="minTimestamp"
            :duration="duration"
            :time-window="timeWindow"
            @fetch-screenshots="$emit('fetch-screenshots', { timeWindow: $event, maxVisibleScreenshots: isDialogOpen ? 100 : 50 })"
            @click:screenshot="handleScreenshotClick"
            :selected-screenshot="selectedScreenshot"
            :max-visible-screenshots="isDialogOpen ? 100 : 50"
          />
        </v-card>
        <div class="d-flex align-center gap-2">
          <v-range-slider
            v-model="timeWindow"
            :min="minTimestampDefault"
            :max="maxTimestampDefault"
            hide-details
          />
          <eva-button
            dense
            text
            icon="mdi-magnify-plus-outline"
            @click="zoomCentered({ zoomIn: true })"
          />
          <eva-button
            dense
            text
            icon="mdi-magnify-minus-outline"
            @click="zoomCentered({ zoomIn: false })"
          />
        </div>
      </div>
    </v-card>
  </dialog-self-wrapper>
</template>

<script>
import DialogSelfWrapper from "@/components/shared/DialogSelfWrapper.vue"
import QuizzesEventsTimelineEventDetail from "./eventDetail.vue"
import ScreenshotsTimeline from "../../../components/quiz/events-timeline/screenshotsTimeline.vue"
import StudentScreenshotViewer from "../../../components/quiz/events-timeline/StudentScreenshotViewer.vue"
import KatexMd from "../../../components/katex-md.vue"
import timeHelpers from "../../../helpers/time_helpers"
import { onKeyPressed } from "@vueuse/core"
import { computed, ref } from "vue"
import KeyboardTooltip from "@/components/shared/keyboard_tooltip.vue"
import useAutoHideOnInactivity from "@/composables/useAutoHideOnInactivity"

const windowBuffer = 0.5
const defaultZoomStepRatio = 0.2
export default {
  name: "EvalmeeQuizEventsTimeline",
  components: {
    KeyboardTooltip,
    KatexMd,
    QuizzesEventsTimelineEventDetail,
    ScreenshotsTimeline,
    StudentScreenshotViewer,
    DialogSelfWrapper,
  },
  props: {
    longEvents: {
      type: Array,
      required: true,
    },
    shortEvents: {
      type: Array,
      required: true,
    },
    lineEvents: {
      type: Array,
      required: true,
    },
    studentScreenshots: {
      type: Array,
      required: true,
    },
    exerciseEventsSummaries: {
      type: Array,
      required: true,
    },
  },
  emits: ["click:exercise", "fetch-screenshots"],
  data: () => ({
    timeWindow: [0,0],
  }),
  setup(props, { emit }) {
    const hoveredExerciseId = ref(null)
    const isDialogOpen = ref(false)
    const selectedScreenshot = ref(null)

    // Sort screenshots by timestamp
    const sortedScreenshots = computed(() => {
      return props.studentScreenshots
        .slice()
        .sort((a, b) => new Date(a.happened_at) - new Date(b.happened_at))
    })

    // Get index of currently selected screenshot
    const selectedScreenshotIndex = computed(() => {
      return sortedScreenshots.value.findIndex(
        screenshot => screenshot.id === selectedScreenshot.value?.id
      )
    })

    // Navigation methods
    const selectPreviousScreenshot = () => {
      if (selectedScreenshotIndex.value > 0) {
        selectedScreenshot.value = sortedScreenshots.value[selectedScreenshotIndex.value - 1]
      }
    }

    const selectNextScreenshot = () => {
      if (selectedScreenshotIndex.value < sortedScreenshots.value.length - 1) {
        selectedScreenshot.value = sortedScreenshots.value[selectedScreenshotIndex.value + 1]
      }
    }

    onKeyPressed("e", () => {
      emitExerciseHoveredClick()
    })

    const emitExerciseHoveredClick = () => {
      emit("click:exercise", hoveredExerciseId.value)
    }

    const handleScreenshotClick = (screenshot) => {
      selectedScreenshot.value = screenshot
      isDialogOpen.value = true
    }

    const {
      isVisible: showControls,
      requestVisibility: requestShowControls,
      setHovered: setControlsHovered,
    } = useAutoHideOnInactivity({ timeout: 2000 })

    return {
      hoveredExerciseId,
      isDialogOpen,
      selectedScreenshot,
      handleScreenshotClick,
      showControls,
      requestShowControls,
      setControlsHovered,
      selectNextScreenshot,
      selectPreviousScreenshot,
    }
  },
  computed: {
    timeHelpers() {
      return timeHelpers
    },
    minTimestampDefault() {
      const timestamps = [
        ...this.longEvents.map(event => event.events.map(e => e.start)).flat(),
        ...this.shortEvents.map(event => event.events.map(e => e.date)).flat(),
        ...this.lineEvents.map(event => event.date),
      ]

      // Math.min(...[]) returns Infinity,
      // so we need to handle the case where there are no timestamps
      return timestamps.length > 0 ? Math.min(...timestamps) : 0
    },
    maxTimestampDefault() {
      const timestamps = [
        ...this.longEvents.map(event => event.events.map(e => e.end)).flat(),
        ...this.shortEvents.map(event => event.events.map(e => e.date)).flat(),
        ...this.lineEvents.map(event => event.date),
      ]
      return Math.max(...timestamps, 0)
    },
    duration() {
      return this.maxTimestamp - this.minTimestamp
    },
    sortedLineEvents() {
      return [...this.lineEvents].sort((a, b) => a.date - b.date)
    },
    filteredLongEvents() {
      return this.longEvents.map(eventsGroup  => {
        return {
          ...eventsGroup,
          events: eventsGroup.events.filter(event => {
            const start = event.start
            const end = event.end

            const minTimestamp = this.minTimestamp - this.duration * windowBuffer
            const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
            // return if event is in the time window even partially
            return (start >= minTimestamp && start <= maxTimestamp) ||
              (end >= minTimestamp && end <= maxTimestamp)
          }),
        }
      })
    },
    filteredShortEvents() {
      return this.shortEvents.map(eventsGroup  => {
        return {
          ...eventsGroup,
          events: eventsGroup.events.filter(event => {
            const date = event.date

            const minTimestamp = this.minTimestamp - this.duration * windowBuffer
            const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
            // return if event is in the time window even partially
            return date >= minTimestamp && date <= maxTimestamp
          }),
        }
      })
    },
    filteredLineEvents() {
      return this.sortedLineEvents.filter(event => {
        const date = event.date

        const minTimestamp = this.minTimestamp - this.duration * windowBuffer
        const maxTimestamp = this.maxTimestamp + this.duration * windowBuffer
        // return if event is in the time window even partially
        return date >= minTimestamp && date <= maxTimestamp
      })
    },
    minTimestamp() {
      return this.timeWindow[0]
    },
    maxTimestamp() {
      return this.timeWindow[1]
    },
    moveStep() {
      return this.timeWindowDuration / 5
    },
    timeWindowDuration() {
      return this.maxTimestamp - this.minTimestamp
    },
    showLeftButton() {
      return this.minTimestamp > this.minTimestampDefault
    },
    showRightButton() {
      return this.maxTimestamp < this.maxTimestampDefault
    },
    showEventTabId() {
      // return true if there is at least 2 different tab token  in shortEvents
      return this.eventTabTokens.filter(Boolean).length > 1
    },
    eventTabTokens() {
      return this.shortEvents.flatMap(event => event.events.map(e => e.details?.browser_tab_token)).filter((value, index, self) => self.indexOf(value) === index)
    },
    exerciseEventsSummary() {
      return (exerciseId) => this.exerciseEventsSummaries.find(e => e.exercise_id === exerciseId)
    },
  },
  methods: {
    updateDefaultTimeWindow() {
      this.timeWindow = [this.minTimestampDefault, this.maxTimestampDefault]
    },
    moveToLeft() {
      const newMinTimestamp = Math.max(this.minTimestamp - this.moveStep, this.minTimestampDefault)
      this.timeWindow = [newMinTimestamp, newMinTimestamp + this.timeWindowDuration]
    },
    moveToRight() {
      const newMaxTimestamp = Math.min(this.maxTimestamp + this.moveStep, this.maxTimestampDefault)
      this.timeWindow = [newMaxTimestamp - this.timeWindowDuration, newMaxTimestamp]
    },
    // Return events by group with same content to highlight copy/paste matches
    similarTimeEventsByGroup(event) {
      return this.shortEvents.filter(x=> x.kind !== "answers_last_update").map(shortEventGroup => {
        return {
          ...shortEventGroup,
          events: shortEventGroup.events.filter(shortEvent => {
            if (event === shortEvent) return false
            if (event.sanitized_content_for_similarity_check === null) return false

            return shortEvent.sanitized_content_for_similarity_check === event.sanitized_content_for_similarity_check
          }),
        }
      }).filter(group => group.events.length > 0)
    },
    tabIdFor(tabToken) {
      return this.eventTabTokens.indexOf(tabToken) + 1
    },
    handleTimelineDoubleClick(event) {
      // Get the relative click position in the timeline (as percentage)
      const timelineRect = event.currentTarget.getBoundingClientRect()
      const clickPosition = (event.clientX - timelineRect.left) / timelineRect.width

      // Calculate timestamp corresponding to click position
      const clickTimestamp = this.minTimestamp + (this.duration * clickPosition)

      // Calculate new duration (zoom x2 = duration divided by 2)
      const newDuration = this.timeWindowDuration / 2

      // Calculate new bounds centered on click
      const newMinTimestamp = Math.max(
        clickTimestamp - (newDuration / 2),
        this.minTimestampDefault
      )
      const newMaxTimestamp = Math.min(
        newMinTimestamp + newDuration,
        this.maxTimestampDefault
      )

      // Adjust minTimestamp if we exceed maxTimestampDefault
      const finalMinTimestamp = newMaxTimestamp === this.maxTimestampDefault
        ? this.maxTimestampDefault - newDuration
        : newMinTimestamp

      this.timeWindow = [finalMinTimestamp, newMaxTimestamp]
    },
    zoomCentered({ zoomIn } = { zoomIn: true }) {
      const direction = zoomIn ? 1 : -2
      const newMinTimestamp = this.minTimestamp + (direction * this.timeWindowDuration * defaultZoomStepRatio)
      const newMaxTimestamp = this.maxTimestamp - (direction * this.timeWindowDuration * defaultZoomStepRatio)
      this.timeWindow = [newMinTimestamp, newMaxTimestamp]
    },
  },
  watch: {
    longEvents: {
      immediate: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
    shortEvents: {
      immediateDefault: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
    lineEvents: {
      immediate: true,
      handler() {
        this.updateDefaultTimeWindow()
      },
    },
  },
}
</script>

<style scoped>
.timeline {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.eventGroup {
  position: relative;
  /* keep margin to 24 and height to 0 to allow to to hover div behind an event group line the line overlay */
  height: 0;
  margin-bottom: 24px;
  width: 100%;
}

.shortEventGroup {
  position: relative;
  height: 0;
  margin-bottom: 24px;
}

.short-event-marker, .rectangle {
  position: absolute;
  height: 20px;
  overflow: hidden;
}

.short-event-marker-background {
  width: 100%;
  height: 100%;
  font-size: 10px;
  color: white;
  font-weight: 700;
  display: flex;
  align-content: center;
  justify-content: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5)
}

.rectangle-background {
  width: 100%;
  height: 100%;
}

.rectangle {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.dot{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid white;
  transform: translate(-10px);
  transition: background-color 0.2s ease-in-out;
  margin-top: 2px;
}

.short-event-marker-line {
  width: 16px;
  transform: translate(-7px);
  padding-left: 7px;
  padding-right: 7px;
  background: transparent;
}
.short-event-marker-line:hover {
  transform: translate(-7px, -1px) scale(1.2);
  height: 300px; /* dirty hack to extend the line to the top and bottom of the timeline */

}
.short-event-marker-line > * {
  border-radius: 2px;
}

.big-short-event-marker {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  margin-left: 3px;
}

.dot:hover {
  transform: translate(-10px, -1px) scale(1.2);
}

.line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 1px solid #00000008;
}

.hover-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  opacity: 0.2
}

.line:hover + .hover-overlay, .hover-overlay:hover {
  opacity: 0.7;
}

.event-icon{
  height: 28px;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.timeline-container{
  width: 100%;
}

.move-button {
  position: absolute;
  top: calc(50% - 14px);
  z-index: 2;
  min-width: 30px !important;
}

.smooth-move{
  transition: 0.2s ease-in-out;
}
#screenshots-container{
  height: 30px;
}

.screenshot-marker{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 40px;
  position: absolute;
}
.floating-timeline{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.controls-container{
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  opacity: 0;
  z-index: 10;
}

.floating-header{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.hideable{
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.hideable-show{
  opacity: 1;
}
</style>
