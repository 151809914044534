<template>
  <div>
    <portal to="drawerTitle">
      Chat
    </portal>
    <portal to="drawer">
      <chat-messages
        :quiz="quiz"
        :channel-id="channel?.id"
        @create-channel="createChannel"
        style="height: 100%;"
        class="pb-10"
      />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ChatMessages from "../../quiz/chat/ChatMessages.vue"

export default {
  name: "ScoreChatChannel",
  components: {
    ChatMessages,
  },
  props: {
    score: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["channels", "channelByQuizAndUserId", "quizById"]),
    quiz() {
      return this.score.quiz || this.quizById(this.score.quiz_id)
    },
    channel() {
      return this.channelByQuizAndUserId(this.quiz?.id, this.score.user_id)
    },
  },
  methods: {
    ...mapActions(["fetchChannelsByQuiz", "createChannelsForQuiz"]),
    async createChannel() {
      return await this.createChannelsForQuiz({
        quiz: this.quiz,
        userId: this.score.user_id,
      })
    },
  },
  created() {
    this.fetchChannelsByQuiz(this.quiz)
  },
  watch: {
    "score.id": {
      handler() {
        this.fetchChannelsByQuiz(this.quiz)
      },
    },
  },
}
</script>

