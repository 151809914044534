<template>
  <div>
    <!-- Normal view -->
    <div class="fullscreen-wrapper">
      <slot />
    </div>

    <!-- Dialog view -->
    <v-dialog
      v-model="isFullscreen"
      max-widthe="90vw"
      max-heighte="90vh"
      content-class="dialog-containere"
    >
      <v-card>
        <v-card-text class="pa-0">
          <slot />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "VDialogSelfWrapper",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    clickToFullscreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFullscreen: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.isFullscreen = newVal
    },
    isFullscreen(newVal) {
      this.$emit("input", newVal)
      this.$emit(newVal ? "enter-fullscreen" : "exit-fullscreen")
    },
  },
  methods: {
    handleClick() {
      if (this.clickToFullscreen && !this.isFullscreen) {
        this.isFullscreen = true
      }
    },
    exitFullscreen() {
      this.isFullscreen = false
    },
  },
}
</script>

<style scoped>

.dialog-container {
  display: flex;
  height: 90vh;
}

.dialog-card {
  height: 100%;
  width: 100%;
}

.dialog-content {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

/* Pour s'assurer que le contenu du slot prend toute la hauteur disponible */
.dialog-content :deep(> *) {
  flex: 1;
  height: 100%;
  min-height: 0;
}
</style>

