import { ref } from "vue"
import { useTimeoutFn } from "@vueuse/core"

export default function useAutoHideOnInactivity({ timeout = 3000 } = {}) {
  const isVisible = ref(false)
  const isHovered = ref(false)

  const {
    start: startVisibilityTimeout,
    stop: stopVisibilityTimeout,
  } = useTimeoutFn(() => {
    // Don't hide if element is being hovered
    if (!isHovered.value) {
      isVisible.value = false
    }
    stopVisibilityTimeout()
  }, timeout)

  const requestVisibility = () => {
    isVisible.value = true
    stopVisibilityTimeout()
    startVisibilityTimeout()
  }

  const requestHide = () => {
    isVisible.value = false
    stopVisibilityTimeout()
  }

  const setHovered = (value) => {
    isHovered.value = value
    if (value) {
      // When hovering, ensure element is visible
      isVisible.value = true
      stopVisibilityTimeout()
    } else {
      // When leaving hover, start the visibility timeout
      startVisibilityTimeout()
    }
  }

  return {
    isVisible,
    isHovered,
    requestVisibility,
    requestHide,
    setHovered,
  }
}

