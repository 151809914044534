<template>
  <div
    v-if="visibleScreenshotsBySegment.length"
    id="screenshots-container"
    class="blue-grey lighten-5"
  >
    <div
      v-for="screenshot in visibleScreenshotsBySegment"
      :key="screenshot.id"
      class="screenshot-marker"
      :style="{ left: `${screenshot.position}%` }"
    >
      <v-img
        :src="screenshot.thumb_url || screenshot.url"
        class="screenshot-thumbnail"
        max-width="40"
        max-height="30"
        min-height="30"
        @click="handleScreenshotClick(screenshot)"
        :class="{
          'selected-screenshot': selectedScreenshot?.id === screenshot.id,
        }"
      />
    </div>
  </div>
</template>

<script>
import { useDebounceFn } from "@vueuse/core"
import { computed, ref, toRefs, watch } from "vue"

const DEBOUNCE_MS = 500

export default {
  name: "ScreenshotsTimeline",
  props: {
    studentScreenshots: {
      type: Array,
      required: true,
    },
    minTimestamp: {
      type: Number,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    timeWindow: {
      type: Array,
      required: true,
    },
    selectedScreenshot: {
      type: Object,
      required: false,
      default: null,
    },
    maxVisibleScreenshots: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  emits: ["fetch-screenshots", "click:screenshot"],
  setup(props, { emit }) {
    // Debounced time window for calculations
    const debouncedTimeWindow = ref(props.timeWindow)
    const { selectedScreenshot, maxVisibleScreenshots } = toRefs(props)

    // Update debounced time window
    const updateDebouncedTimeWindow = useDebounceFn(
      (newTimeWindow) => {
        debouncedTimeWindow.value = newTimeWindow
        // Uncomment the line below to fetch screenshots from the backend
        emit("fetch-screenshots", newTimeWindow)
      },
      DEBOUNCE_MS
    )

    // Watch for time window changes
    watch(
      () => props.timeWindow,
      (newTimeWindow) => {
        updateDebouncedTimeWindow(newTimeWindow)
      },
      { immediate: true }
    )
    watch(
      () => maxVisibleScreenshots.value,
      () => emit("fetch-screenshots", debouncedTimeWindow.value),
      { immediate: true }
    )

    // Compute visible screenshots based on time segments
    const visibleScreenshotsBySegment = computed(() => {
      if (!props.studentScreenshots.length) return []

      const [startTime, endTime] = debouncedTimeWindow.value

      // Calculer la durée d'un segment de temps
      const segmentDuration = (endTime - startTime) / maxVisibleScreenshots.value
      const groups = new Map()

      // Filtrer les screenshots dans la plage de temps
      const filteredScreenshots = props.studentScreenshots
        .filter(screenshot => {
          const time = new Date(screenshot.happened_at).getTime()
          return time >= startTime && time <= endTime
        })

      // Distribuer les screenshots dans les segments de temps
      filteredScreenshots.forEach(screenshot => {
        const time = new Date(screenshot.happened_at).getTime()
        const segmentIndex = Math.floor((time - startTime) / segmentDuration)

        // Ne garder que le premier screenshot de chaque segment
        if (!groups.has(segmentIndex) && segmentIndex < maxVisibleScreenshots.value) {
          groups.set(segmentIndex, screenshot)
        }
      })

      const sampledScreenshots = Array.from(groups.values())

      // Combiner les screenshots échantillonnés avec le screenshot sélectionné
      return [...sampledScreenshots, selectedScreenshot.value]
        .filter(Boolean)
        .filter((value, index, self) => self.findIndex(t => t.id === value.id) === index)
        .map(screenshot => ({
          ...screenshot,
          position: ((new Date(screenshot.happened_at).getTime() - props.minTimestamp) / props.duration * 100),
        }))
        .sort((a, b) => a.position - b.position)
    })

    const handleScreenshotClick = (screenshot) => {
      emit("click:screenshot", screenshot)
    }

    return {
      visibleScreenshotsBySegment,
      handleScreenshotClick,
    }
  },
  data: () => ({
    showScreenshotDialog: false,
  }),
}
</script>

<style scoped>
#screenshots-container {
  height: 30px;
}

.screenshot-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 40px;
  position: absolute;
}

.selected-screenshot {
  border: 2px solid #007bff;
  zoom: 1.4;
  margin-top: -10%;
}
</style>
